import { ReactComponent as InfoIcon } from 'assets/svg/infoButton.svg';
import { useContext, useEffect, useRef, useState } from 'react';
import { appContext } from 'state/context';
import { autoExpandOnChange } from 'utils/general';
import { ReactComponent as CopyIconSvg } from 'assets/svg/copyIcon.svg';
import { logCopyPressed } from 'logs/logFunctions';

const ChatOutput = () => {
    const ctx = useContext(appContext);
    const { isSmallScreen } = ctx?.settingsState || {};
    const { output } = ctx?.appState || {};
    const { context_id } = ctx?.trackContext || {};
    const { setAppState } = ctx || {};
    const outputRef = useRef<HTMLTextAreaElement>(null);
    const [isOutputInfoClicked, setIsOutputInfoClicked] = useState(false);
    const [isCopyClicked, setIsCopyClicked] = useState(false);

    const copyToClipboard = async () => {
      try {
        await navigator?.clipboard?.writeText(outputRef.current?.value || "");
        setIsCopyClicked(true);
      }
      catch (err) {
        console.log(err);
        return
      }
      finally {
        setTimeout(() => {
          setIsCopyClicked(false);
          logCopyPressed(context_id, outputRef.current?.value)
        }, 1000);
      };
    };

    const onClickOutputInfo= () => {
      setIsOutputInfoClicked(true);
        setTimeout(() => {
          setIsOutputInfoClicked(false);
        }, 2000)
    }

    const InfoIconWithTooltip = () => {
      return isSmallScreen ?
        (
          <div onClick={onClickOutputInfo}>
            <InfoIcon />
          </div>
        )
        :
        (<div
          onMouseEnter={() => setIsOutputInfoClicked(true)}
          onMouseLeave={() => setIsOutputInfoClicked(false)}
        >
          <InfoIcon />
        </div>
        )
    };

    const InfoTooltips = () => {
      return isOutputInfoClicked ? (
        isSmallScreen ? (
          <div 
            className={`relative text-[11px] text-[#EEEEEE] font-semibold flex justify-center items-center bg-[#2C2523] w-[200px] p-1 h-[110px]`}
            style={{border: "1px solid #504C4A", zIndex: 999, top: 15, left: 5}}>
              This 'Output Field' displays the text generated based on your Prompt Input and the Sports Context. Press the submit arrow after entering your prompt to view the results here.
          </div>
        ) :
        (
          <div 
            className='relative top-8 -right-2 text-[11px] text-[#EEEEEE] font-semibold 
                          flex justify-center items-center bg-[#2C2523] p-1 w-[250px] h-[90px]'
            style={{border: "1px solid #504C4A", zIndex: 99}}>
            This 'Output Field' displays the text generated based on your Prompt Input and the Sports Context. Press the submit arrow after entering your prompt to view the results here.
          </div>
        )
      ) :
      (isSmallScreen ? 
        (null)
        : (
            <div 
              className='relative -top-5 z-15 text-[11px] text-[#EEEEEE] font-semibold w-auto
                      flex justify-center items-center bg-transparent h-[19px]'>
            </div>
          )
      )
    }

    const CopyIconAndTooltip = () => {
      return (
        <>
          <div
                className='absolute right-2 top-1 cursor-pointer p-1 hover:bg-[#343541]/10 rounded-lg'
                onClick={copyToClipboard}
                onCopyCapture={copyToClipboard}
                title="Copy to clipboard">
                <CopyIconSvg />
          </div>
          {isCopyClicked ? (
            <div className='absolute right-3 -top-5 z-10 text-1214 text-[#CCCCCC] font-semibold- rounded-lg w-auto h-auto px-2 py-1
          flex justify-center bg-[#343541]/90'>Copied to clipboard</div>
          ) : null}
        </>
      )
    }
    
    useEffect(() => {
      const handleResizeInput = () => {
          let outputCurrentRef = outputRef.current;
          if (outputCurrentRef) {
            outputCurrentRef.style.height = 'inherit';
            outputCurrentRef.style.height = `${outputCurrentRef.scrollHeight}px`;
          }
      };

      handleResizeInput();
      window.addEventListener('resize', handleResizeInput);

      return () => {
          window.removeEventListener('resize', handleResizeInput);
      };
    }, [output]);

    useEffect(() => {
      const outputListener = document.querySelector("div.output-listener");

      outputListener && outputListener.addEventListener("copy", (event) => {
        logCopyPressed(context_id, outputRef.current?.value)
      })
    }, []); 

    return  (
        <>
          <div className='flex flex-row mt-4 text-[#CCCCCC] w-[100%] text-1619 mb-2 ml-8 gap-2 h-[19px]'>
            Output
            <InfoIconWithTooltip />
            <InfoTooltips />
          </div>
          <div data-testid={'output'} className='output-listener relative w-[100%] min-h-[80px] h-auto border-2 border-[#CCCCCC] rounded-2xl py-4 pl-4 text-[#6B6B6B] text-1619 bg-[#111111]' >
            <textarea id="output-textarea" className='w-[98%] resize-none border-none focus:outline-none custom-scrollbar-transparent text-[#CCCCCC] text-1619 bg-[#111111]'
              readOnly
              ref={outputRef}
              onChange={(e) => autoExpandOnChange(e, 'output', setAppState)}
              style={{ height: 'auto', minHeight: '48px' }}
              value={output}
            >
            </textarea>
            <CopyIconAndTooltip />
        </div>
        </>
    )
};

export default ChatOutput;
